import {useCallback, useContext, useMemo, useState} from 'react';
import {Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {AlertsContext, FormikInput, FormikNumberInput, ProgressIndicator} from '@reasoncorp/kyber-js';

import {CountySelect, LocalUnitSelect, SearchCard, StatesSelect} from '../components/shared';
import {exemptionApi} from '../api';
import * as messages from '../messages';
import {dashboardSearchSchema} from '../schema';
import {ExemptionLite} from '../types';
import {DashboardFormFields} from '../types/form';
import {ExemptionSearchRequest} from '../types/request';
import {useConfiguration} from '../hooks';
import {ExemptionResultsTable, ExemptionSearchValuesJumbotron} from '../components/exemption';

const Dashboard = () => {
  const {showErrorAlert} = useContext(AlertsContext);
  const {configuration, findLocalUnitById, findCountyById} = useConfiguration();
  const [exemptions, setExemptions] = useState<ExemptionLite[]>([]);
  const [searchState, setSearchState] = useState({searchPerformed: false, searching: false});

  const initialValues: DashboardFormFields = useMemo(() => ({
    reasonId: '',
    parcelNumber: '',
    propertyCountyId: null,
    propertyLocalUnitId: null,
    ownerCompanyName: '',
    ownerFirstName: '',
    ownerLastName: '',
    coOwnerFirstName: '',
    coOwnerLastName: '',
    mailingStreetNumber: '',
    mailingStreetName: '',
    mailingCity: '',
    mailingState: '',
    propertyStreetNumber: '',
    propertyStreetName: '',
    propertyCity: '',
    prePercent: ''
  }), []);

  const handleSearch = useCallback(async (dashboardFormFields: DashboardFormFields,
                                          formikHelpers: FormikHelpers<DashboardFormFields>) => {
    try {
      setSearchState(searchState => ({...searchState, searching: true}));
      const exemptionSearchRequest: ExemptionSearchRequest = {
        years: configuration.currentYears,
        reasonId: dashboardFormFields.reasonId.trim(),
        parcelNumber: dashboardFormFields.parcelNumber.trim(),
        propertyCountyId: dashboardFormFields.propertyCountyId,
        propertyLocalUnitId: dashboardFormFields.propertyLocalUnitId,
        ownerCompanyName: dashboardFormFields.ownerCompanyName.trim(),
        ownerFirstName: dashboardFormFields.ownerFirstName.trim(),
        ownerLastName: dashboardFormFields.ownerLastName.trim(),
        coOwnerFirstName: dashboardFormFields.coOwnerFirstName.trim(),
        coOwnerLastName: dashboardFormFields.coOwnerLastName.trim(),
        mailingStreetNumber: dashboardFormFields.mailingStreetNumber.trim(),
        mailingStreetName: dashboardFormFields.mailingStreetName.trim(),
        mailingCity: dashboardFormFields.mailingCity.trim(),
        mailingState: dashboardFormFields.mailingState.trim(),
        propertyStreetNumber: dashboardFormFields.propertyStreetNumber.trim(),
        propertyStreetName: dashboardFormFields.propertyStreetName.trim(),
        propertyCity: dashboardFormFields.propertyCity.trim(),
        prePercent: dashboardFormFields.prePercent.length !== 0 ? Number(dashboardFormFields.prePercent) : null
      };

      await formikHelpers.setValues({
        ...exemptionSearchRequest,
        reasonId: dashboardFormFields.reasonId.trim(),
        prePercent: dashboardFormFields.prePercent
      });

      const exemptionResults = await exemptionApi.search(exemptionSearchRequest);
      setExemptions(exemptionResults);
      setSearchState({searchPerformed: true, searching: false});
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setSearchState({searchPerformed: false, searching: false});
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }, [
    configuration.currentYears,
    showErrorAlert
  ]);

  const handleReset = useCallback(() => {
    setExemptions([]);
    setSearchState(searchState => ({...searchState, searchPerformed: false}));
  }, []);

  return (
    <Container fluid aria-label="search">
      <Formik initialValues={initialValues}
              validationSchema={dashboardSearchSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSearch}>
        {(formikProps) => (
          <>
            <div className="mb-4">
              <SearchCard headerText="Search"
                          submitDisabled={formikProps.isSubmitting}
                          resetDisabled={formikProps.isSubmitting}
                          onReset={handleReset}>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Reason ID"
                                 name="reasonId"/>
                  </Col>
                  <Col md={2}>
                    <FormikInput labelText="Parcel ID"
                                 name="parcelNumber"/>
                  </Col>
                  <Col md={3}>
                    <CountySelect name="propertyCountyId"
                                  onChange={() => formikProps.setFieldValue('propertyLocalUnitId', null)}/>
                  </Col>
                  <Col md={3}>
                    <LocalUnitSelect name="propertyLocalUnitId"
                                     countyId={formikProps.values.propertyCountyId}/>
                  </Col>
                  <Col md={2}>
                    <FormikInput labelText="Owner"
                                 name="ownerCompanyName"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <FormikInput labelText="Owner First"
                                 name="ownerFirstName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Owner Last"
                                 name="ownerLastName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Co-Owner First"
                                 name="coOwnerFirstName"/>
                  </Col>
                  <Col md={3}>
                    <FormikInput labelText="Co-Owner Last"
                                 name="coOwnerLastName"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Mail Street #"
                                 name="mailingStreetNumber"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Mail Street Name"
                                 name="mailingStreetName"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Mail City"
                                 name="mailingCity"/>
                  </Col>
                  <Col md={2}>
                    <StatesSelect name="mailingState"/>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <FormikInput labelText="Prop Street #"
                                 name="propertyStreetNumber"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Prop Street Name"
                                 name="propertyStreetName"/>
                  </Col>
                  <Col md={4}>
                    <FormikInput labelText="Prop City"
                                 name="propertyCity"/>
                  </Col>
                  <Col md={2}>
                    <FormikNumberInput labelText="PRE %"
                                       name="prePercent"
                                       maxLength={3}/>
                  </Col>
                </Row>
              </SearchCard>
            </div>
            <ExemptionSearchValuesJumbotron county={findCountyById(formikProps.values.propertyCountyId)}
                                            localUnit={findLocalUnitById(formikProps.values.propertyLocalUnitId)}
                                            query={{
                                              years: configuration.currentYears,
                                              ...formikProps.values
                                            }}/>
          </>
        )}
      </Formik>
      {searchState.searching && <ProgressIndicator/>}
      <Card className={!searchState.searchPerformed || searchState.searching ? 'd-none' : 'mt-3'}>
        <CardHeader>Search Results</CardHeader>
        <ExemptionResultsTable exemptions={exemptions}/>
      </Card>
    </Container>
  );
};

export default Dashboard;
