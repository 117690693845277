import * as Yup from 'yup';

import * as messages from '../messages';

const exportFilterFormSchema = Yup.object().shape({
  majorReason: Yup.string(),
  startDate: Yup.date()
    .nullable()
    .typeError(messages.MUST_BE_VALID_DATE),
  endDate: Yup.date()
    .nullable()
    .typeError(messages.MUST_BE_VALID_DATE)
    .min(Yup.ref('startDate'), messages.END_DATE_BEFORE_START_DATE)
});

export default exportFilterFormSchema;