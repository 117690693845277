import * as Yup from 'yup';

export default Yup.object({
  reasonId: Yup.string(),
  parcelNumber: Yup.string(),
  propertyCountyId: Yup.number().nullable(),
  propertyLocalUnitId: Yup.number().nullable(),
  ownerCompanyName: Yup.string(),
  ownerFirstName: Yup.string(),
  ownerLastName: Yup.string(),
  coOwnerFirstName: Yup.string(),
  coOwnerLastName: Yup.string(),
  mailingStreetNumber: Yup.string(),
  mailingStreetName: Yup.string(),
  mailingCity: Yup.string(),
  mailingState: Yup.string(),
  propertyStreetNumber: Yup.string(),
  propertyStreetName: Yup.string(),
  propertyCity: Yup.string(),
  prePercent: Yup.number().nullable()
});