import {memo} from 'react';
import {Card, CardHeader, Table} from 'reactstrap';

import {formatDate} from '../../util';
import {Exemption} from '../../types';

type Props = {
  exemption: Exemption
  className?: string
}

const PreCard = ({
                   exemption,
                   className
                 }: Props) => {
  return <Card className={`PreCard ${className}`}>
    <CardHeader>PRE Information</CardHeader>
    <Table responsive size="sm" striped>
      <tbody>
        <tr>
          <th scope="row" className="border-0 p-2 pl-4 pr-4">PRE %</th>
          <th className="border-0 font-weight-normal text-dark p-2 pl-4 pr-4">
            {Math.round(exemption.prePercent)}%
          </th>
          <th scope="row" className="border-0 p-2 pl-4 pr-4">PRE Date</th>
          <th className="border-0 font-weight-normal text-dark p-2 pl-4 pr-4">
            {formatDate(exemption.preOn)}
          </th>
        </tr>
        <tr>
          <th scope="row" className="p-2 pl-4 pr-4">Exempt Flag</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {exemption.agFlag}
          </th>
          <th scope="row" className="p-2 pl-4 pr-4">Rescinded Date</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {formatDate(exemption.rescindedOn)}
          </th>
        </tr>
        <tr>
          <th scope="row" className="p-2 pl-4 pr-4">Adjacent Parcel</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {exemption.adjacentParcel}
          </th>
          <th scope="row" className="p-2 pl-4 pr-4">Denial Date</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {formatDate(exemption.deniedOn)}
          </th>
        </tr>
      </tbody>
    </Table>
  </Card>;
};

export default memo(PreCard);