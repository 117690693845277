import {useMemo} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, Row} from 'reactstrap';
import {Formik, FormikProps} from 'formik';

import {FormikInput, FormikSelect} from '@reasoncorp/kyber-js';

import {ownerSchema} from '../../schema';
import {Owner} from '../../types';
import CleanButtons from './CleanButtons';
import StatesSelect from './StatesSelect';

type Props = {
  onCancel: () => void
  onUpdate: (ownerRequest: Owner) => void
  owner: Owner
  toggleEdit?: () => void
  isEditing: boolean,
  showCleanButtons?: boolean,
  onClean?: (ownerRequest: Owner) => void,
  onSkip?: () => void
}

const OwnerCard = ({
                     isEditing,
                     onCancel,
                     onUpdate,
                     toggleEdit = () => {
                     },
                     owner,
                     showCleanButtons = false,
                     onClean = () => {
                     },
                     onSkip = () => {
                     }
                   }: Props) => {

  const renderCardHeader = useMemo(() => (formikProps?: FormikProps<Owner>) => {
    if (showCleanButtons) {
      return (
        <CardHeader>Owner Information</CardHeader>
      );
    } else if (isEditing && formikProps) {
      return (
        <CardHeader>
          <Row>
            <Col className="align-self-center">Owner Information</Col>
            <Col className="justify-content-end d-flex">
              <Button type="submit"
                      size="sm"
                      color="success"
                      className="mr-2"
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Update
              </Button>
              <Button size="sm"
                      color="danger"
                      onClick={onCancel}>
                Cancel
              </Button>
            </Col>
          </Row>
        </CardHeader>
      );
    } else {
      return (
        <CardHeader>
          <Row>
            <Col className="align-self-center">Owner Information</Col>
            <Col hidden={showCleanButtons} className="justify-content-end d-flex">
              <Button size="sm"
                      color="primary"
                      onClick={toggleEdit}>
                Edit
              </Button>
            </Col>
          </Row>
        </CardHeader>
      );
    }
  }, [
    isEditing,
    onCancel,
    showCleanButtons,
    toggleEdit
  ]);

  const renderOriginalOwnerInfo = useMemo(() => () => {
    return (
      <>
        <Row className="pl-2 pr-2 pb-1">
          <Col>
            <strong className="mr-1">Original Owner 1:</strong>
            <span className="text-uppercase">{owner.originalName1}</span>
          </Col>
        </Row>
        <Row className="pl-2 pr-2 pb-1 pt-1">
          <Col>
            <strong className="mr-1">Original Owner 2:</strong>
            <span className="text-uppercase">{owner.originalName2}</span>
          </Col>
        </Row>
        <Row className="border-bottom pl-2 pr-2 pb-2 pt-1 mb-2">
          <Col>
            <strong className="mr-1">Original Address:</strong>
            <span className="text-uppercase">{owner.originalAddress}</span>
          </Col>
        </Row>
      </>
    );
  }, [
    owner
  ]);

  const renderItem = useMemo(() => (key: string, value: string) => {
    return <>
      <Row>
        <Col className="font-weight-bold">
          {key}
        </Col>
      </Row>
      <Row>
        <Col>
          {value}&nbsp;
        </Col>
      </Row>
    </>;
  }, []);

  const renderViewOnlyCard = useMemo(() => () => {
    return <Card className="mb-4">
      {renderCardHeader()}
      <CardBody>
        {renderOriginalOwnerInfo()}
        <Row className="border-bottom p-2 pb-3 pt-3">
          <Col sm={9}>
            {renderItem('Owner', owner.companyName)}
          </Col>
          <Col sm={3}>
            {renderItem('Flag', owner.trustFlag)}
          </Col>
        </Row>
        <Row className="border-bottom p-2 pb-3 pt-3">
          <Col>
            {renderItem('First', owner.firstName)}
          </Col>
          <Col>
            {renderItem('Middle', owner.middleName)}
          </Col>
          <Col>
            {renderItem('Last', owner.lastName)}
          </Col>
          <Col>
            {renderItem('Suffix', owner.suffix)}
          </Col>
        </Row>
        <Row className="border-bottom p-2 pb-3 pt-3">
          <Col>
            {renderItem('Co First', owner.coFirstName)}
          </Col>
          <Col>
            {renderItem('Co Middle', owner.coMiddleName)}
          </Col>
          <Col>
            {renderItem('Co Last', owner.coLastName)}
          </Col>
          <Col>
            {renderItem('Co Suffix', owner.coSuffix)}
          </Col>
        </Row>
        <Row className="border-bottom p-2 pb-3 pt-3">
          <Col>
            {renderItem('3rd Owner', owner.careOf)}
          </Col>
        </Row>
        <Row className="border-bottom p-2 pb-3 pt-3">
          <Col sm={2}>
            {renderItem('Street #', owner.address.streetNumber)}
          </Col>
          <Col sm={2}>
            {renderItem('Dir 1', owner.address.directionPrefix)}
          </Col>
          <Col sm={3}>
            {renderItem('Street Name', owner.address.streetName)}
          </Col>
          <Col sm={2}>
            {renderItem('Type', owner.address.streetType)}
          </Col>
          <Col sm={2}>
            {renderItem('Dir 2', owner.address.directionSuffix)}
          </Col>
        </Row>
        <Row className="p-2 pb-0 pt-3">
          <Col>
            {renderItem('Unit', owner.address.unit)}
          </Col>
          <Col>
            {renderItem('City', owner.address.city)}
          </Col>
          <Col>
            {renderItem('State', owner.address.state)}
          </Col>
          <Col>
            {renderItem('Zip ', owner.address.zip)}
          </Col>
        </Row>
      </CardBody>
    </Card>;
  }, [
    owner,
    renderCardHeader,
    renderItem,
    renderOriginalOwnerInfo
  ]);

  const renderEditCard = useMemo(() => () => {
    return <Formik initialValues={{...owner}}
                   validateOnMount={true}
                   enableReinitialize={true}
                   onSubmit={onUpdate}
                   validationSchema={ownerSchema}>
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Card className="mb-4">
            {renderCardHeader(formikProps)}
            <CardBody>
              {renderOriginalOwnerInfo()}
              <Row className="p-2">
                <Col sm={9}>
                  <FormikInput labelText="Owner"
                               aria-label="Owner Company Name"
                               name="companyName"
                               maxLength="100"/>
                </Col>
                <Col sm={3}>
                  <FormikSelect name="trustFlag"
                                labelText="Flag">
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="9">9</option>
                    <option value="11">11</option>
                  </FormikSelect>
                </Col>
              </Row>
              <Row className="pl-2 pr-2">
                <Col>
                  <FormikInput labelText="First"
                               aria-label="Owner First Name"
                               name="firstName"
                               maxLength="100"/>
                </Col>
                <Col>
                  <FormikInput labelText="Middle"
                               aria-label="Owner Middle Name"
                               name="middleName"
                               maxLength="20"/>
                </Col>
                <Col>
                  <FormikInput labelText="Last"
                               aria-label="Owner Last Name"
                               name="lastName"
                               maxLength="50"/>
                </Col>
                <Col>
                  <FormikInput labelText="Suffix"
                               aria-label="Owner Suffix"
                               name="suffix"
                               maxLength="20"/>
                </Col>
              </Row>
              <Row className="pl-2 pr-2">
                <Col>
                  <FormikInput labelText="Co First"
                               aria-label="Co Owner First Name"
                               name="coFirstName"
                               maxLength="50"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Middle"
                               aria-label="Co Owner Middle Name"
                               name="coMiddleName"
                               maxLength="20"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Last"
                               aria-label="Co Owner Last Name"
                               name="coLastName"
                               maxLength="35"/>
                </Col>
                <Col>
                  <FormikInput labelText="Co Suffix"
                               aria-label="Co Owner Suffix"
                               name="coSuffix"
                               maxLength="20"/>
                </Col>
              </Row>
              <Row className="pl-2 pr-2">
                <Col>
                  <FormikInput labelText="3rd Owner"
                               aria-label="3rd Owner Name"
                               name="careOf"
                               maxLength="100"/>
                </Col>
              </Row>
              <Row className="pl-2 pr-2">
                <Col sm={2}>
                  <FormikInput labelText="Street #"
                               aria-label="Owner Street Number"
                               name="address.streetNumber"
                               maxLength="15"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Dir 1"
                               aria-label="Owner Direction Prefix"
                               name="address.directionPrefix"
                               maxLength="15"/>
                </Col>
                <Col sm={4}>
                  <FormikInput labelText="Street Name"
                               aria-label="Owner Street Name"
                               name="address.streetName"
                               maxLength="75"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Type"
                               aria-label="Owner Street Type"
                               name="address.streetType"
                               maxLength="35"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Dir 2"
                               aria-label="Owner Direction Suffix"
                               name="address.directionSuffix"
                               maxLength="15"/>
                </Col>
              </Row>
              <Row className="pr-2 pl-2">
                <Col sm={4}>
                  <FormikInput labelText="Unit"
                               aria-label="Owner Unit"
                               name="address.unit"
                               maxLength="35"/>
                </Col>
                <Col sm={4}>
                  <FormikInput labelText="City"
                               aria-label="Owner City"
                               name="address.city"
                               maxLength="25"/>
                </Col>
                <Col sm={2}>
                  <StatesSelect name="address.state"/>
                </Col>
                <Col sm={2}>
                  <FormikInput labelText="Zip"
                               aria-label="Owner Zip Code"
                               name="address.zip"
                               maxLength="11"/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {showCleanButtons &&
            <CleanButtons onClean={() => onClean(formikProps.values)}
                          onSkip={onSkip}
                          onUpdate={formikProps.handleSubmit}
                          onCancel={onCancel}/>
          }
        </Form>)}
    </Formik>;
  }, [
    owner,
    onUpdate,
    onCancel,
    onClean,
    onSkip,
    renderCardHeader,
    renderOriginalOwnerInfo,
    showCleanButtons
  ]);

  return isEditing ? renderEditCard() : renderViewOnlyCard();
};

export default OwnerCard;
