import {useCallback, useMemo} from 'react';
import {Col, Jumbotron, Row} from 'reactstrap';

import {County, LocalUnit} from '@reasoncorp/kyber-js';

type Props = {
  [field: string]: any
  county: County | null
  localUnit: LocalUnit | null
}

const ExemptionSearchValuesJumbotron = ({
                                          query,
                                          county,
                                          localUnit
                                        }: Props) => {
  const renderQueryValue = useMemo(() => (key: string, value: string, index: number) => {
    return <Col md={4} lg={3} key={index}>
      <span>{key}: <strong>{value}</strong></span>
    </Col>;
  }, []);

  const mapQueryKeyToLabel = useCallback((queryKey: string) => {
    return {
      'years': 'Years',
      'reasonId': 'Reason ID',
      'parcelNumber': 'Parcel ID',
      'ownerCompanyName': 'Owner',
      'ownerFirstName': 'Owner First',
      'ownerLastName': 'Owner Last',
      'coOwnerFirstName': 'Co-Owner First',
      'coOwnerLastName': 'Co-Owner Last',
      'prePercent': 'PRE %',
      'mailingStreetNumber': 'Mailing Street #',
      'mailingStreetName': 'Mailing Street Name',
      'mailingCity': 'Mailing City',
      'mailingState': 'State',
      'propertyStreetNumber': 'Prop Street #',
      'propertyStreetName': 'Prop Street Name',
      'propertyCity': 'Prop City'
    }?.[queryKey] ?? queryKey;
  }, []);

  return <Jumbotron className="p-2">
    <Row>
      {Object.keys(query).map((queryKey, i) => {
        const value = Array.isArray(query[queryKey]) ? query[queryKey].join(', ') : query[queryKey];

        if (queryKey === 'propertyCountyId' && county) {
          return renderQueryValue('County', county.displayName, i);
        } else if (queryKey === 'propertyLocalUnitId' && localUnit) {
          return renderQueryValue('City/Township', localUnit.displayNameWithType, i);
        } else if (value !== '' && value !== null) {
          return renderQueryValue(mapQueryKeyToLabel(queryKey), value, i);
        } else {
          return null;
        }
      })}
    </Row>
  </Jumbotron>;
};

export default ExemptionSearchValuesJumbotron;