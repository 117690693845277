import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, Formik} from 'formik';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row} from 'reactstrap';

import {BreadcrumbsNav, FormikSelect, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {reportApi} from '../../api';
import * as messages from '../../messages';
import {useConfiguration} from '../../hooks';
import {NonResponseReportModal} from '../../components/report';

const Reports = () => {
  const navigate = useNavigate();
  const {configuration} = useConfiguration();
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [year, setYear] = useState(configuration.defaultReportYear);
  const [isExportReportDisabled, setIsExportReportDisabled] = useState(false);
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false,
    exportingReport: false
  });
  const [progressModalTitle, setProgressModalTitle] = useState('');
  const [isNonResponseModalOpen, setIsNonResponseModalOpen] = useState(false);

  const cleanedRecordsCardDisabled = useMemo(() => year < 2023, [year]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setYear(Number(e.target.value));
  }, []);

  // If there are no export batches in a given year, the card should be disabled
  useEffect(() => {
    const loadExportBatches = async () => {
      try {
        const exportBatches = await reportApi.findExportBatches(Number(year));
        setIsExportReportDisabled(exportBatches.length === 0);
        setLoadingState({loadError: false, loading: false, exportingReport: false});
      } catch (error) {
        setLoadingState({loadError: true, loading: false, exportingReport: false});
        showErrorAlert(messages.API_FAILURE);
      }
    };

    void loadExportBatches();
  }, [year, showErrorAlert]);

  const handleDownloadReport = useCallback(async (
    progressModalTitle: string,
    downloadReport: Promise<Response>
  ) => {
    setProgressModalTitle(progressModalTitle);

    try {
      setLoadingState({loadError: false, loading: false, exportingReport: true});
      await downloadReport;
      showSuccessAlert(messages.REPORT_DOWNLOAD_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.REPORT_DOWNLOAD_FAILED);
    }
    setLoadingState({loading: false, loadError: false, exportingReport: false});
  }, [
    showSuccessAlert,
    showErrorAlert
  ]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator className="mb-4"/>}
    {!loadingState.loading && !loadingState.loadError && <>
      <BreadcrumbsNav breadcrumbs={[
        {text: 'Reports', active: true}
      ]}/>
      <Row className="mt-2">
        <Col>
          <Formik initialValues={{year}}
                  onSubmit={async () => null}>
            {(_) => (
              <Form autoComplete="off" className="mb-0">
                <Row className="d-flex justify-content-between">
                  <Col xs="12" sm="6" md="3" lg="2">
                    <FormikSelect name="year"
                                  labelText="Year"
                                  onChange={handleChange}>
                      {configuration.reportYears.map(year => <option value={year}>{year}</option>)}
                    </FormikSelect>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Export Report
            </CardHeader>
            <CardBody>
              YTD Report of Export Lists and Summaries
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      onClick={() => navigate(`/reports/export/${year}`)}
                      disabled={isExportReportDisabled || loadingState.exportingReport}>
                Open
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Cleaned Records Report
            </CardHeader>
            <CardBody>
              YTD Report Tracking Cleaned Records by Examiner
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      onClick={() => navigate(`/reports/cleaned-records/${year}`)}
                      disabled={cleanedRecordsCardDisabled || loadingState.exportingReport}>
                Open
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Valid Records Report
            </CardHeader>
            <CardBody>
              YTD Report of all Valid Records
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      onClick={() => navigate(`/reports/valid-records/${year}`)}
                      disabled={loadingState.exportingReport}>
                Open
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Letter Sent Report
            </CardHeader>
            <CardBody>
              YTD Report Displaying Letter Sent Dates
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      onClick={() => handleDownloadReport(
                        'Generating Letter Sent Report',
                        reportApi.downloadLetterSentReport(Number(year))
                      )}
                      disabled={loadingState.loading || loadingState.loadError || loadingState.exportingReport}>
                Download
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Questionnaire Review Status Report
            </CardHeader>
            <CardBody>
              YTD Report Displaying the Review Status of Questioned Parcel
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      disabled={loadingState.exportingReport}
                      onClick={() => handleDownloadReport(
                        'Generating Questionnaire Review Status Report',
                        reportApi.downloadQuestionnaireReport(Number(year))
                      )}>
                Download
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col className="col-4">
          <Card className="h-100">
            <CardHeader>
              Non Response Report
            </CardHeader>
            <CardBody>
              Report Displaying Any Parcel with Letter Sent Date but No Mail Returned Checked in AuditPro
            </CardBody>
            <CardFooter className="d-flex justify-content-end">
              <Button color="primary"
                      disabled={loadingState.exportingReport || isNonResponseModalOpen}
                      onClick={() => setIsNonResponseModalOpen(true)}>
                Download
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>

      <NonResponseReportModal isOpen={isNonResponseModalOpen}
                              setIsOpen={setIsNonResponseModalOpen}/>

      <ProgressModal isOpen={loadingState.exportingReport}
                     title={progressModalTitle}
                     content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
    </>}
  </Container>;
};

export default Reports;