import {useMemo} from 'react';

import {CustomTable} from '@reasoncorp/kyber-js';

import {ExemptionLite} from '../../types';
import {formatNumber, openAndFocus} from '../../util';

type Props = {
  exemptions: ExemptionLite[]
}

const ExemptionResultsTable = ({
                                 exemptions
                               }: Props) => {
  const tableProps = useMemo(() => ({
    headers: [
      {sortKey: 'reasonId', title: 'Reason ID'},
      {sortKey: 'parcelNumber', title: 'Parcel ID'},
      {sortKey: 'property.county.displayName', title: 'County'},
      {sortKey: 'property.localUnit.displayNameWithType', title: 'City/Township'},
      {sortKey: 'property.address.streetNumber', title: 'Prop St #'},
      {sortKey: 'property.address.streetNameWithTypeAndDirections', title: 'Prop St Name'},
      {sortKey: 'property.address.unit', title: 'Prop Unit'},
      {sortKey: 'property.address.city', title: 'Prop City'},
      {sortKey: 'owner.firstName', title: 'Owner First'},
      {sortKey: 'owner.lastName', title: 'Owner Last'},
      {sortKey: 'owner.coFirstName', title: 'Co-Owner First'},
      {sortKey: 'owner.coLastName', title: 'Co-Owner Last'},
      {sortKey: 'owner.companyName', title: 'Owner'},
      {sortKey: 'property.classification', title: 'P. Class'},
      {sortKey: 'year', title: 'Year'},
      {sortKey: 'prePercent', title: 'PRE %'},
      {sortKey: 'property.taxValue', title: 'Tax Val'}
    ],
    chainSort: true,
    items: exemptions,
    renderRow: (exemption: ExemptionLite) => {
      return (
        <tr key={exemption.id}>
          <td role="link">
            <a href={`/exemptions/${exemption.id}`}
               onClick={((e) => openAndFocus(e, `/exemptions/${exemption.id}`, 'priorYearView'))}>
              {exemption.reasonId}
            </a>
          </td>
          <td>
            {exemption.parcelNumber}
          </td>
          <td className="text-uppercase">
            {exemption.property.county.displayName}
          </td>
          <td className="text-uppercase text-nowrap">
            {exemption.property.localUnit.displayNameWithType}
          </td>
          <td>
            {exemption.property.address.streetNumber}
          </td>
          <td>
            {exemption.property.address.streetNameWithTypeAndDirections}
          </td>
          <td>
            {exemption.property.address.unit}
          </td>
          <td>
            {exemption.property.address.city}
          </td>
          <td>
            {exemption.owner.firstName}
          </td>
          <td>
            {exemption.owner.lastName}
          </td>
          <td>
            {exemption.owner.coFirstName}
          </td>
          <td>
            {exemption.owner.coLastName}
          </td>
          <td>
            {exemption.owner.companyName}
          </td>
          <td>
            {exemption.property.classification}
          </td>
          <td>
            {exemption.year}
          </td>
          <td>
            {exemption.prePercent}
          </td>
          <td>
            {formatNumber(exemption.property.taxValue)}
          </td>
        </tr>
      );
    },
    noResultsMessage: 'No exemptions match your search.'
  }), [
    exemptions
  ]);

  return <CustomTable {...tableProps}/>;
};

export default ExemptionResultsTable;