import {Owner, UncleanDetail, UncleanRecord} from '../types';
import {auditProApi} from './apiUtils';

export const find = (id: number): Promise<Owner> => auditProApi.getWithJsonResponse(
  `/owners/${id}`
);

export const update = (id: number, ownerRequest: Owner): Promise<Owner> => auditProApi.patchWithJsonResponse(
  `/owners/${id}`,
  {
    body: JSON.stringify(ownerRequest)
  }
);

export const findDirty = async (): Promise<UncleanDetail[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty`
);

export const findDirtyByCountyId = async (countyId: number): Promise<UncleanDetail[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty/${countyId}`
);

export const searchDirty = (countyId: number,
                            localUnitId: number): Promise<UncleanRecord[]> => auditProApi.getWithJsonResponse(
  `/owners/dirty/search?countyId=${countyId}&localUnitId=${localUnitId}`
);

export const skip = async (id: number) => auditProApi.patchWithJsonResponse(
  `/owners/${id}/skip`
);

export const clean = async (id: number) => auditProApi.patchWithJsonResponse(
  `/owners/${id}/clean`
);