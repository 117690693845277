// Application strings
import {formatDate} from './util';

export const APP_TITLE = 'AuditPro - An Official State of Michigan Web Site';
export const APP_TITLE_PARCEL_VIEW = 'AuditPro - Parcel View';

// Growl notification messages
export const API_FAILURE = 'Unable to communicate with the AuditPro service. Check your connection and try refreshing the page.';
export const AUDIT_SAVE_SUCCESSFUL = 'Audit Updated.';
export const AUDIT_SAVE_FAILURE = 'Unable to update Audit.';
export const DRIVER_LICENSE_VIEW_FAILURE = 'Error viewing drivers license numbers.';
export const DUPLICATE_SAVE_SUCCESSFUL = 'Duplicate Created.';
export const DUPLICATE_SAVE_FAILURE = 'Error creating duplicate.';
export const EXPORTS_SUCCESSFUL = 'Export Successful.';
export const EXPORTS_FAILURE = 'Error exporting exemptions.';
export const EXPORTS_LOAD_FAILURE = 'Error Loading Exports.';
export const HISTORY_DELETE_SUCCESSFUL = 'History Record Deleted.';
export const HISTORY_DELETE_FAILURE = 'Error Deleting Record.';
export const OWNER_LOAD_FAILURE = 'Error Loading Owner.';
export const OWNER_SAVE_SUCCESSFUL = 'Owner Updated.';
export const OWNER_SAVE_FAILURE = 'Error Updating Owner.';
export const PROPERTY_SAVE_SUCCESSFUL = 'Property Updated.';
export const PROPERTY_SAVE_FAILURE = 'Error Updating Property.';
export const QUESTIONNAIRE_SAVE_SUCCESSFUL = 'Letter Send Created.';
export const QUESTIONNAIRE_SAVE_FAILURE = 'Error Creating Send Letter.';
export const RECORD_CLEAN_FAILURE = 'Error Cleaning Record.';
export const RECORD_CLEAN_SUCCESS = 'Record Cleaned.';
export const RECORD_SKIP_FAILURE = 'Error Skipping Record.';
export const RECORD_SKIP_SUCCESS = 'Record Skipped.';
export const REPORT_DOWNLOAD_FAILED = 'Unable to download report.';
export const REPORT_DOWNLOAD_SUCCESSFUL = 'Download successful.';
export const REPORT_LOAD_FAILURE = 'Error Loading report.';
export const SCAN_DOWNLOAD_FAILURE = 'Unable to download scan.';
export const UNCLEAN_LOAD_FAILURE = 'Error Loading Unclean Townships.';

// Validation messages
export const BAD_FILE_FORMAT = 'File format must be PDF.';
export const MAX_FILE_SIZE = 'File size must be smaller than 25MB.';
export const ONE_FIELD_REQUIRED = 'At least one field is required.';
export const OWNER_FLAG_ERROR = 'Must be 1, 2, or 3.';
export const PRE_PERCENT_RANGE_ERROR = 'Must be between and 0 and 100';
export const END_DATE_BEFORE_START_DATE = 'End date before start date.';
export const MUST_BE_VALID_DATE = 'Must be a valid date.';
export const MUST_BE_EARLIER_THAN = (maxDate: Date) => `Must be earlier than ${formatDate(maxDate)}`;

export const REQUIRED = 'Required';
