import {createContext} from 'react';

import {AuditProConfiguration, ConfigurationContextType} from '../types';

export default createContext<ConfigurationContextType>({
  configuration: {
    currentYears: [],
    reportYears: [],
    defaultReportYear: new Date().getFullYear(),
    counties: [],
    localUnits: []
  },
  filterLocalUnitsByCountyId: (_?: number | string | null) => [],
  findCountyById: (_?: number | string | null) => null,
  findLocalUnitById: (_?: number | string | null) => null,
  setConfiguration: (configuration: AuditProConfiguration) => configuration
});