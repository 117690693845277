import {Col, Jumbotron, Row} from 'reactstrap';

import {formatNumber} from '../../util';
import {Exemption} from '../../types';

type Props = {
  exemption: Exemption
}

const ParcelPanel = ({exemption}: Props) => {
  return (
    <Jumbotron className="pt-2 pb-2">
      <Row>
        <Col md={3}>
          <strong className="mr-1 no-select">Reason ID:</strong>
          <span className="selectable">{exemption.reasonId}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">County:</strong>
          <span className="text-uppercase selectable">{exemption.property.county.displayName}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">Property Class:</strong>
          <span className="selectable">{exemption.property.classification}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">Prior Years Audited:</strong>
          <span className="selectable">{exemption.priorYears.join(', ')}</span>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <strong className="mr-1 no-select">Parcel ID:</strong>
          <span className="selectable">{exemption.parcelNumber}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">City/Township:</strong>
          <span className="text-uppercase selectable">{exemption.property.localUnit.displayNameWithType}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">Current Value:</strong>
          <span className="selectable">{formatNumber(exemption.property.taxValue)}</span>
        </Col>
        <Col md={3}>
          <strong className="mr-1 no-select">Program Year:</strong>
          <span className="selectable">{exemption.year}</span>
        </Col>
      </Row>
    </Jumbotron>
  );
};

export default ParcelPanel;