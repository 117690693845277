import {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {sum} from 'lodash';

import {BreadcrumbsNav, CustomTable, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {UncleanDetail} from '../types';
import {ownerApi} from '../api';
import * as messages from '../messages';
import {useConfiguration} from '../hooks';

const CleanCounty = () => {
  const navigate = useNavigate();
  const {findCountyById} = useConfiguration();
  const {countyId} = useParams();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const {showErrorAlert} = useAlerts();
  const [dirtyRecords, setDirtyRecords] = useState<UncleanDetail[]>([]);
  const [totalDirtyRecords, setTotalDirtyRecords] = useState<number>(0);

  const county = useMemo(() => findCountyById(countyId), [
    countyId,
    findCountyById
  ]);

  const breadcrumbs = useMemo(() => ([
    {text: 'Clean', route: '/clean'},
    {text: county?.displayName ?? '', active: true}
  ]), [
    county
  ]);

  useEffect(() => {
    const loadDirtyOwners = async () => {
      try {
        const dirtyRecords = await ownerApi.findDirtyByCountyId(Number(countyId));
        setDirtyRecords(dirtyRecords);
        setTotalDirtyRecords(sum(dirtyRecords.map(dirtyRecord => dirtyRecord.count)));
        setLoadingState({loading: false, loadError: false});
      } catch (error) {
        showErrorAlert(messages.UNCLEAN_LOAD_FAILURE);
        setLoadingState({loading: false, loadError: true});
      }
    };

    void loadDirtyOwners();
  }, [
    countyId,
    showErrorAlert
  ]);

  const handleRecordClick = useCallback((uncleanDetail: UncleanDetail) => {
    navigate(`/clean/${uncleanDetail.countyId}/${uncleanDetail.localUnit.id}`);
  }, [
    navigate
  ]);

  const tableProps = useMemo(() => ({
    headers: [],
    items: dirtyRecords,
    noResultsMessage: 'No records to clean',
    renderRow: (uncleanDetail: UncleanDetail) => {
      return (
        <tr key={uncleanDetail.localUnit.id}
            className="cursor-pointer"
            onClick={() => handleRecordClick(uncleanDetail)}>
          <td className="align-left pl-3 w-85">
            {uncleanDetail.localUnit.displayNameWithType}
          </td>
          <td className="text-center">
            <Badge color="dark">{uncleanDetail.count}</Badge>
          </td>
        </tr>
      );
    }
  }), [
    dirtyRecords,
    handleRecordClick
  ]);

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loading && !loadingState.loadError &&
        <>
          <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
          <Row>
            <Col xs="8" md="4">
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      Select Township
                    </Col>
                    <Col className="justify-content-end d-flex">
                      Total {totalDirtyRecords}
                    </Col>
                  </Row>
                </CardHeader>
                <CustomTable {...tableProps}/>
              </Card>
            </Col>
          </Row>
        </>
      }
    </Container>
  );
};

export default CleanCounty;