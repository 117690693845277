import {ReactNode, useCallback, useState} from 'react';

import ConfigurationContext from './ConfigurationContext';
import {AuditProConfiguration} from '../types';

type Props = {
  children: ReactNode
}

const ConfigurationProvider = ({children}: Props) => {
  const [configuration, setConfiguration] = useState<AuditProConfiguration>({
    currentYears: [],
    reportYears: [],
    defaultReportYear: new Date().getFullYear(),
    counties: [],
    localUnits: []
  });

  const filterLocalUnitsByCountyId = useCallback((countyId?: number | string | null) => {
    return configuration.localUnits.filter(lu => lu.countyId === Number(countyId));
  }, [
    configuration.localUnits
  ]);

  const findCountyById = useCallback((countyId?: number | string | null) => {
    return configuration.counties.filter(county => county.id === Number(countyId))?.[0] ?? null;
  }, [
    configuration.counties
  ]);

  const findLocalUnitById = useCallback((localUnitId?: number | string | null) => {
    return configuration.localUnits.filter(localUnit => localUnit.id === Number(localUnitId))?.[0] ?? null;
  }, [
    configuration.localUnits
  ]);

  return (
    <ConfigurationContext.Provider value={{
      configuration,
      setConfiguration,
      filterLocalUnitsByCountyId,
      findCountyById,
      findLocalUnitById
    }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;