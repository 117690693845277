import {memo} from 'react';
import {Card, CardHeader, Table} from 'reactstrap';

import {formatDate} from '../../util';
import {Sale} from '../../types';

type Props = {
  sale?: Sale
  className?: string
}

const SaleCard = ({
                    sale,
                    className
                  }: Props) => {
  return <Card className={`SaleCard ${className}`}>
    <CardHeader>Sale Information</CardHeader>
    <Table responsive size="sm" striped>
      <tbody>
        <tr>
          <th scope="row" className="border-0 p-2 pl-4 pr-4">Sale Date 1</th>
          <th className="border-0 font-weight-normal text-dark p-2 pl-4 pr-4">
            {formatDate(sale?.dateOfSale1)}
          </th>
          <th scope="row" className="border-0 p-2 pl-4 pr-4">Sale Date 2</th>
          <th className="border-0 font-weight-normal text-dark p-2 pl-4 pr-4">
            {formatDate(sale?.dateOfSale2)}
          </th>
        </tr>
        <tr>
          <th scope="row" className="p-2 pl-4 pr-4">Grantor 1</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {sale?.grantor1}
          </th>
          <th scope="row" className="p-2 pl-4 pr-4">Grantor 2</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {sale?.grantor2}
          </th>
        </tr>
        <tr>
          <th scope="row" className="p-2 pl-4 pr-4">Grantee 1</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {sale?.grantee1}
          </th>
          <th scope="row" className="p-2 pl-4 pr-4">Grantee 2</th>
          <th className="font-weight-normal text-dark p-2 pl-4 pr-4">
            {sale?.grantee2}
          </th>
        </tr>
      </tbody>
    </Table>
  </Card>;
};

export default memo(SaleCard);