import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers, FormikValues} from 'formik';
import {union} from 'lodash';
import {isWithinInterval} from 'date-fns';

import {
  ConfirmationModal,
  CustomTable,
  FormikDatePicker,
  FormikModal,
  FormikSelect,
  Paginator,
  PendingExport,
  ProgressIndicator,
  SelectTableCell,
  SelectTableCellData,
  useAlerts
} from '@reasoncorp/kyber-js';

import * as messages from '../messages';
import {exportsApi} from '../api';
import {formatDate, parseDate} from '../util';
import {exportFilterFormSchema} from '../schema';

type Props = {
  numberSelected: number,
  onExport: () => void
  onModalToggle: (isOpen: boolean) => void
  onFilterReset: () => void
}

const ExportControls = ({
                          numberSelected,
                          onExport,
                          onFilterReset,
                          onModalToggle
                        }: Props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-end align-items-center">
        <Button color="primary"
                className="mr-2"
                onClick={() => onModalToggle(true)}>
          Filter
        </Button>
        <Button color="danger"
                className="mr-2"
                onClick={() => onFilterReset()}>
          Reset Filter
        </Button>
        <Button color="success"
                disabled={numberSelected === 0}
                onClick={onExport}>
          Export
        </Button>
      </Col>
    </Row>
  );
};

const today = new Date();

const Exports = () => {
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({
    loading: true,
    loadError: false
  });
  const [isExporting, setIsExporting] = useState(false);
  const [pendingExports, setPendingExports] = useState<PendingExport[]>([]);
  const [filteredPendingExports, setFilteredPendingExports] = useState<PendingExport[]>([]);
  const [showExportConfirmationModal, setShowExportConfirmationModal] = useState(false);
  const numberSelected = filteredPendingExports.filter(pendingExport => pendingExport.selected).length;
  const majorReasons = union(pendingExports.map(pendingExport => pendingExport.majorReason)).sort();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState<{
    majorReason?: string,
    startDate?: Date,
    endDate?: Date
  }>({});
  const [currentPage, setCurrentPage] = useState(0);

  const initialValues = useMemo(() => ({
    majorReason: '',
    startDate: null,
    endDate: null
  }), []);

  const handleSelectAll = useCallback((data: SelectTableCellData) => {
    setFilteredPendingExports(filteredPendingExports.map(pendingExport => data.itemIds.includes(pendingExport.id) ? {
      ...pendingExport,
      selected: !data.prevSelection
    } : pendingExport));
  }, [filteredPendingExports]);

  const handleItemSelect = useCallback((data: SelectTableCellData) => {
    setFilteredPendingExports(filteredPendingExports.map(pendingExport => pendingExport.id === data.itemId ? {
      ...pendingExport,
      selected: !data.prevSelection
    } : pendingExport));
  }, [filteredPendingExports]);

  const areFiltersApplied = useMemo(() => {
    const {
      majorReason,
      startDate,
      endDate
    } = filterOptions;
    return majorReason || (startDate && endDate);
  }, [filterOptions]);

  const paginatorProps = useMemo(() => ({
    perPage: 250,
    recordName: areFiltersApplied ? 'filtered exemptions' : 'exemptions',
    currentPage,
    handleChange: setCurrentPage,
    totalItems: filteredPendingExports.length
  }), [areFiltersApplied, currentPage, filteredPendingExports]);

  const tableProps = useMemo(() => ({
    className: 'mb-0',
    items: filteredPendingExports,
    noResultsMessage: 'No exemptions match for export.',
    headers: [
      {selectKey: 'selected', dataIdKey: 'id', onChange: handleSelectAll},
      {title: 'Reason ID', sortKey: 'reasonId', className: 'text-center align-middle'},
      {title: 'Parcel ID', sortKey: 'parcelNumber', className: 'text-center align-middle'},
      {title: 'County', sortKey: 'county.displayName'},
      {title: 'City/Township', sortKey: 'localUnit.displayNameWithType'},
      {title: 'Owner', sortKey: 'owner'},
      {title: 'Address', sortKey: 'address'},
      {title: 'Major Reason', sortKey: 'majorReason'},
      {title: 'Final Review Date', sortKey: 'finalReviewAt'}
    ],
    initialSort: {sortKey: 'reasonId', direction: 'asc' as const},
    paginatorConfig: {...paginatorProps, hidePaginator: true},
    renderRow: ({
                  id,
                  selected,
                  exemptionId,
                  reasonId,
                  parcelNumber,
                  county,
                  localUnit,
                  owner,
                  address,
                  majorReason,
                  finalReviewAt
                }: PendingExport) => {
      return (
        <tr key={id}>
          <SelectTableCell itemId={id}
                           ariaLabel={`Toggle select exemption ${reasonId}`}
                           selected={selected}
                           onChange={handleItemSelect}/>
          <td className="text-center align-middle" role="link">
            <a href={`/exemptions/${exemptionId}`}
               target="_blank"
               rel="noopener noreferrer">
              {reasonId}
            </a>
          </td>
          <td className="text-center align-middle">
            {parcelNumber}
          </td>
          <td className="align-middle text-uppercase">
            {county.displayName}
          </td>
          <td className="align-middle text-nowrap text-uppercase">
            {localUnit.displayNameWithType}
          </td>
          <td className="align-middle">
            {owner}
          </td>
          <td className="align-middle">
            {address}
          </td>
          <td className="align-middle">
            {majorReason}
          </td>
          <td className="align-middle">
            {formatDate(finalReviewAt)}
          </td>
        </tr>
      );
    }
  }), [filteredPendingExports, handleItemSelect, handleSelectAll, paginatorProps]);

  const handleExport = useCallback(async () => {
    setIsExporting(true);
    const exemptionIds = filteredPendingExports.filter(pendingExport => pendingExport.selected)
      .map(pendingExport => pendingExport.exemptionId);
    try {
      await exportsApi.createBatch(exemptionIds);

      setFilterOptions({});
      const pendingExports = await exportsApi.findPending();
      setPendingExports(pendingExports);
      showSuccessAlert(messages.EXPORTS_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.EXPORTS_FAILURE);
    }
    setIsExporting(false);
    setShowExportConfirmationModal(false);
  }, [filteredPendingExports, showSuccessAlert, showErrorAlert]);

  useEffect(() => {
      const loadPendingExports = async () => {
        try {
          const pendingExports = await exportsApi.findPending();
          setPendingExports(pendingExports);
          setFilteredPendingExports(pendingExports);
          setLoadingState({loadError: false, loading: false});
        } catch (error) {
          showErrorAlert(messages.EXPORTS_LOAD_FAILURE);
          setLoadingState({loading: false, loadError: true});
        }
      };

      void loadPendingExports();
    },
    [showErrorAlert]
  );

  const filterPendingExport = useCallback((pendingExport: PendingExport) => {
    const {
      majorReason = ''
    } = filterOptions;
    const doesMajorReasonMatchOrOmitted = majorReason !== '' ?
      pendingExport.majorReason === majorReason :
      true;
    const finalReviewAt = parseDate(pendingExport.finalReviewAt) !== undefined ?
      parseDate(pendingExport.finalReviewAt) :
      undefined;
    const startDate = filterOptions.startDate ? parseDate(filterOptions.startDate) : undefined;
    const endDate = filterOptions.endDate ? parseDate(filterOptions.endDate) : undefined;
    const isWithinDateRangeOrIsOmitted = startDate !== undefined &&
    endDate !== undefined && finalReviewAt !== undefined ?
      isWithinInterval(finalReviewAt, {start: startDate, end: endDate}) : true;

    // If the user has selected a majorReason, and it does not match, immediately filter out
    // Otherwise if the user has selected a majorReason, and it does match,
    // check the date range if the user has provided a startDate and endDate
    return doesMajorReasonMatchOrOmitted && isWithinDateRangeOrIsOmitted;
  }, [filterOptions]);

  useEffect(() => {
      setFilteredPendingExports(pendingExports.filter(filterPendingExport));
    },
    [pendingExports, filterOptions, filterPendingExport]
  );

  const renderMajorReasonOption = useMemo(() => (majorReason: string) =>
    <option key={majorReason} value={majorReason}>
      {majorReason}
    </option>, []);

  const handleModalCancel = useCallback(() => setIsModalOpen(false), []);

  const handleModalSubmit = useCallback(async (values: FormikValues,
                                               formikHelpers: FormikHelpers<FormikValues>) => {
    setFilterOptions(values);
    formikHelpers.setSubmitting(false);
    setIsModalOpen(false);
  }, []);

  const renderFilterHeader = useMemo(() => () => {
    const {
      majorReason,
      startDate,
      endDate
    } = filterOptions;
    return areFiltersApplied ? <CardHeader className="bg-light text-secondary">
      <Row className="d-flex">
        <Col md="6">
          <strong className="mr-3">
            Filtered By:
          </strong>
          {majorReason && <div className="mr-3 d-inline-block">
            <span className="font-weight-bold text-primary">Major Reason:</span> {filterOptions.majorReason}
          </div>}
          {startDate && endDate && <div className="d-inline-block">
            <span className="font-weight-bold text-primary">Final Review Date:</span> {formatDate(filterOptions.startDate)} - {formatDate(filterOptions.endDate)}
          </div>}
        </Col>
        <Col md="6" className="text-right font-weight-bold text-primary">
          Filtered Results: {filteredPendingExports.length}
        </Col>
      </Row>
    </CardHeader> : null;
  }, [filterOptions, areFiltersApplied, filteredPendingExports]);

  return (
    <Container fluid>
      {loadingState.loading && <ProgressIndicator/>}
      {!loadingState.loadError && !loadingState.loading &&
        <>
          <ExportControls numberSelected={numberSelected}
                          onModalToggle={setIsModalOpen}
                          onFilterReset={() => setFilterOptions({})}
                          onExport={() => setShowExportConfirmationModal(true)}/>
          <Card className="my-3">
            <CardHeader>
              <Row>
                <Col md="6">
                  Export Queue
                </Col>
                <Col md="6" className="text-right font-weight-bold">
                  Total to Export: {numberSelected}/{pendingExports.length}
                </Col>
              </Row>
            </CardHeader>
            {renderFilterHeader()}
            <CustomTable  {...tableProps}/>
            {renderFilterHeader()}
            <Paginator {...paginatorProps} />
            <CardHeader>
              <Row>
                <Col className="text-right font-weight-bold">
                  Total to Export: {numberSelected}/{pendingExports.length}
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <ExportControls numberSelected={numberSelected}
                          onModalToggle={setIsModalOpen}
                          onFilterReset={() => setFilterOptions({})}
                          onExport={() => setShowExportConfirmationModal(true)}/>
          <ConfirmationModal isOpen={showExportConfirmationModal}
                             size="lg"
                             title="Export Records"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             confirmCallback={() => handleExport()}
                             cancelCallback={() => setShowExportConfirmationModal(false)}
                             confirmButtonDisabled={isExporting}
                             cancelButtonDisabled={isExporting}>
            <p>
              Are you sure you want to export {numberSelected} records?
            </p>
          </ConfirmationModal>
          <FormikModal isOpen={isModalOpen}
                       validationSchema={exportFilterFormSchema}
                       title="Filter Exemptions"
                       submitButtonText="Filter"
                       submitButtonColor="primary"
                       initialValues={initialValues}
                       onCancel={handleModalCancel}
                       onSubmit={handleModalSubmit}>
            <Row className="mb-2">
              <Col>
                <FormikSelect autoFocus
                              name="majorReason"
                              labelText="Major Reason"
                              aria-required={true}
                              formGroupClass="mb-0">
                  <option value="">Select Major Reason</option>
                  {majorReasons.map(renderMajorReasonOption)}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikDatePicker name="startDate"
                                  labelText="Start Date"
                                  maxDate={today}
                                  aria-required="true"/>
              </Col>
              <Col>
                <FormikDatePicker name="endDate"
                                  labelText="End Date"
                                  maxDate={today}
                                  aria-required="true"/>
              </Col>
            </Row>
          </FormikModal>
        </>
      }
    </Container>
  );
};

export default Exports;
