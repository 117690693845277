import {formatBoolean, formatDate, formatDateTime, formatNumber} from './formatting';
import openAndFocus from './openAndFocus';
import {Exemption} from '../types';
import {isValid, parseISO} from 'date-fns';

const isExemptionInCurrentYears = (exemption: Exemption | undefined, currentYears: number[]) => exemption !== undefined && currentYears.includes(exemption.year);

const isNotNullOrBlank = (val: string | undefined | null | number) => val !== null && val !== undefined && val !== '';

const parseDate = (dateValue: Date | string | undefined | null) => {
  if (dateValue instanceof Date && isValid(dateValue)) {
    return dateValue;
  } else {
    const parsedDate = parseISO((dateValue as string));
    return (dateValue != null && isValid(parsedDate)) ? parsedDate : undefined;
  }
};

export {
  formatBoolean,
  formatDate,
  formatDateTime,
  formatNumber,
  isExemptionInCurrentYears,
  isNotNullOrBlank,
  openAndFocus,
  parseDate
};